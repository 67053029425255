@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background: rgb(239,162,35);    
}

#profile-details {
    @apply border-[1px]  p-4 bg-gray-50 mb-4 rounded-2xl py-[5px];
    > div {
        @apply border-b-[1px] py-2;
    }
    > div:last-of-type {
        @apply border-b-0;
    }
    > div > span:first-child {
        min-width: 120px;;
        @apply font-bold w-min w-[10%] inline-block;
    }
}

#footer {
    
    background: rgb(239,162,35);   
    h1 {
       @apply text-xl; 
    }
    @apply py-4 px-7 text-sm;
    &>p {
        @apply py-[.5rem];
    }
}

#profile ul {
    @apply list-disc my-1;

    &>li {
        @apply mx-[2em] py-[.2em];
    }
}

.contact {
    padding:.5em 2em 1em 2em;
    padding-bottom:0;
    border:1px dashed black;
    border-radius:15px;
    margin:1em 0
}
.heading {
    @apply text-xl font-bold pt-2 text-left text-[1.1em];
}
.contact-details {
    @apply mb-4 text-left text-[1.75em] mb-1;
    > span {
        @apply text-red-600 font-bold;
    }
}

#blog-post {
    @apply p-5;
    h1 {
        @apply text-lg font-bold;
    }
}

.searchResults {
    @apply bg-white rounded-r-md z-50;
    li {
        @apply w-full p-4 border-b-[1px] border-b-[#f5f5f5];
    }
}

#message_form {
    &.sending {
        @apply opacity-40;
    }
    #form-success > div {
        @apply border-[1px]  border-green-300 bg-green-200 rounded-md p-4;
    }    
    #form-errors > ul {
        @apply border-[1px] border-red-300 bg-red-200 rounded-md p-4;
    }
}

#success-stories {

    &> div.grid {
     
    
        & > div {
            background: radial-gradient(circle at 0% 100%, #97B49D 0%, 24%, rgba(151,180,157,0) 24%), radial-gradient(circle at 3.9697265625% 36.10026041666667%, #27784F 0%, 35%, rgba(39,120,79,0) 35%), radial-gradient(circle at 27.496744791666668% 92.77994791666667%, #004623 0%, 40%, rgba(0,70,35,0) 40%), radial-gradient(circle at 53.3837890625% 100%, rgba(215,53,1,0.99) 0%, 50%, rgba(215,53,1,0) 50%), radial-gradient(circle at 42.286783854166664% 20.615234375%, #FF4401 0%, 70%, rgba(255,68,1,0) 70%), radial-gradient(circle at 93.6865234375% 11.42578125%, #FFB993 0%, 70%, rgba(255,185,147,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%);

            height: 300px;
            @apply flex rounded-xl p-4 place-items-center text-center;
            
            div > div:first-of-type {
                @apply font-bold;
            }
        }
    }


}


#blog-post {
    @apply md:w-[900px] p-5 md:p-10 mx-auto;

    #feature-image {
        @apply mb-4;
    }
    h1 {
        @apply text-[32px] mb-8;
    }
    h2 {
        @apply text-[24px] font-bold mt-3;

    }
    h3 {
        @apply text-[20px] font-bold mt-3;

    }    
    h4 {
        @apply text-[18px] font-bold mt-3;

    }
    p {
        @apply py-2;
    }

    a {

        color: rgb(239,162,35);    
        text-decoration: underline;

        &:hover {
            color: darken(rgb(239,162,35), 40%);
        }
    }
}

#description {
    @apply p-5 md:p-10;
    h1 {
        @apply text-2xl font-bold py-5;
    }

    h2 {
        @apply text-xl font-bold pt-3;
    }
    p {
        @apply py-4;
    }
}

#blog-post #blog-content {
    img {
        @apply my-2;
    }
}

.new-profiles div:last-of-type {
    @apply hidden md:block;
}